import Vue from 'vue'

// axios
import axios from 'axios'

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(error) {
  if (error.response.status === 401) {
      const keysToRemove = ["accessToken", "refreshToken", "userData"];
      keysToRemove.forEach((k) => localStorage.removeItem(k));

      window.location.href = '/login';
  }
});

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: 'https://api.soclib.net/api/',
})

Vue.prototype.$http = axiosIns

export default axiosIns
