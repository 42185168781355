export default {
  namespaced: true,
  state: {
    alert: {
      visible: false,
      text: undefined,
      count: undefined,
      type: undefined,
    },
  },
  getters: {
    getAlert(state) {
      return state.alert;
    },
  },
  mutations: {
    setAlert(state, alert) {
      state.alert = alert;
    },
    hideAlert(state, flag) {
      state.alert.visible = flag;
    },
  },
  actions: {},
};
