export default [
    // dashboard home page
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/index.vue"),
    },
    // not found (404 page)
    {
        path: "/error-404",
        name: "error-404",
        component: () => import("@/views/error/Error404.vue"),
        meta: {
            layout: "full",
            resource: "Auth",
            action: "read",
        },
    },
    // authentication
    {
        path: "/login",
        name: "auth-login",
        component: () => import("@/views/pages/authentication/Login.vue"),
        meta: {
            layout: "full",
            resource: "Auth",
            redirectIfLoggedIn: true,
        },
    },
    // post
    {
        path: "/dashboard/posts",
        name: "posts-index",
        component: () => import("@/views/pages/posts/index.vue"),
    },
    {
        path: "/dashboard/posts/create",
        name: "posts-create",
        component: () => import("@/views/pages/posts/create.vue"),
    },
    {
        path: "/dashboard/posts/:id/edit",
        name: "posts-edit",
        component: () => import("@/views/pages/posts/edit.vue"),
    },
    //  users
    {
        path: "/dashboard/users",
        name: "users-index",
        component: () => import("@/views/pages/users/index.vue"),
        beforeEnter: (to, from, next) => {
            next();
        },
    },
    {
        path: "/dashboard/users/create",
        name: "users-create",
        component: () => import("@/views/pages/users/create.vue"),
    },
    {
        path: "/dashboard/users/:id/edit",
        name: "users-edit",
        component: () => import("@/views/pages/users/edit.vue"),
    },
    // not authorized
    {
        path: "/not-authorized",
        name: "misc-not-authorized",
        component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
        meta: {
            layout: "full",
            resource: "Auth",
        },
    },
    // maintenance mode
    {
        path: "/under-maintenance",
        name: "misc-under-maintenance",
        component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
        meta: {
            layout: "full",
        },
    },
];
